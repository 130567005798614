import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../layout";
import Gobblet from "../../games/gobblet";
import config from "../../../data/siteconfig";

class PlayPage extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 0 };
  }

  componentDidMount() {
    fetch(config.appScriptExecUrl + "?id=gobblet-play-counter&action=get", {
      method: "GET"
    })
      .then(res => res.json())
      .then(data => this.setState({ count: data["value"] }))
      .catch(err => console.warn(err));
  }

  countCallback = () => {
    fetch(config.appScriptExecUrl + "?id=gobblet-play-counter&action=hit", {
      method: "GET"
    });
    this.setState({ count: this.state.count + 1 });
  };

  render() {
    const posts = this.props.data.allMarkdownRemark.nodes;
    return (
      <Layout title="funstuff">
        <h1>fun things</h1>
        games, <a href="#music">music</a>, and other digital trinkets
        &nbsp;<img class="emote" src="/img/emotes/jester.gif"></img>

        <h3>play a game with me?</h3>
        <p>
          It's just like tic-tac-toe{" "}
          <img class="emote" src="/img/emotes/wink.gif"></img>
        </p>
        <Gobblet mode={"ai"} winCallback={this.countCallback} />
        <p>played {this.state.count || "?"} times</p>
        <h1 id="music">music</h1>
        <h3>guitar chords & tabs</h3>
        <p>
          <img class="emote" src="/img/emotes/new.gif" /> check it out!{" "}
          <a href="https://setlist.trinityjchung.com/">setlist</a>
        </p>
        <h3>sheet music index</h3>
        <p>
          My old stash of transcribed/arranged sheet music for strings, piano,
          and guitar chords/tabs.
        </p>
        {/* <Search
          posts={posts}
          localSearch={this.props.data.localSearchMusic}
          placeholder='Search music posts...'
          prefix='music'
        /> */}
        {posts.map((p, i) => (
          <div key={i}>
            <a href={"/music/" + p.fields.slug}>{p.frontmatter.title}</a>
            {p.frontmatter.tags.map((t, j) => (
              <span key={j}> ~ {t}</span>
            ))}
          </div>
        ))}
        <h3>deprecated</h3>
        <p>
          <a href="https://musescore.com/groups/milotrince">MuseScore</a>
          &nbsp;~&nbsp;
          <a href="https://soundcloud.com/milotrince">SoundCloud</a>
        </p>
      </Layout>
    );
  }
}

export const query = graphql`
  query MusicPageQuery {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "music" } } }
    ) {
      nodes {
        fields {
          slug
          date
        }
        excerpt
        frontmatter {
          title
          tags
          cover
          date
        }
      }
    }
  }
`;

export default PlayPage;
